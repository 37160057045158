
@font-face {
    font-family: "BNR";
    src: url("../../fonts/BebasNeue-Regular.ttf");
}

@font-face {
    font-family: "DG";
    src: url("../../fonts/DarkerGrotesque-Regular.ttf");
}

@font-face {
    font-family: "NR";
    src: url("../../fonts/NotoSansTelugu_Condensed-Regular.ttf");
}

@font-face {
    font-family: "NL";
    src: url("../../fonts/NotoSansTelugu_Condensed-Light.ttf");
}
@font-face {
    font-family: "NT";
    src: url("../../fonts/NotoSansTelugu_Condensed-Thin.ttf");
}

@font-face {
    font-family: "NSB";
    src: url("../../fonts/NotoSansTelugu_Condensed-SemiBold.ttf");
}

@font-face {
    font-family: "ISB";
    src: url("../../fonts/Inter_24pt-SemiBold.ttf");
}

@font-face {
    font-family: "IB";
    src: url("../../fonts/Inter_24pt-Bold.ttf");
}

@font-face {
    font-family: "IR";
    src: url("../../fonts/Inter_24pt-Regular.ttf");
}

@font-face {
    font-family: "II";
    src: url("../../fonts/Inter_24pt-Italic.ttf");
}

@font-face {
    font-family: "RR";
    src: url("../../fonts/RobotoCondensed-Regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #FFFFFF;
}

.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    position: fixed;
    margin: 0 10px;
    background: rgba(240, 240, 240, 1);
    border-radius: 80px;
    padding: 0 20px;
    margin-top: 20px;
    width: -webkit-fill-available;
}

.header-main section {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-main section a {
    font-size: 24px;
    font-family: "NR";
    font-weight: 400;
    font-style: italic;
    cursor: pointer;
}

.header-main section button {
    border: none;
    width: 320px;
    border: 2px solid rgba(35, 35, 35, 1);
    height: 50px;
    border-radius: 40px;
    font-size: 24px;
    color: rgba(28, 28, 28, 1);
    font-weight: 600;
    font-family: "IR";
    cursor: pointer;
    transition: all 0.5s ease;
}

.header-main section button:hover {
    background-color: #dadada;
}

.header-main img {
    cursor: pointer;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);

    }

    100% {
        transform: rotate(360deg);
    }
}

.first-component-div {
    display: flex;
    justify-content: space-between;
    padding: 100px 60px;
    flex-wrap: wrap;
    overflow: hidden;
}

.second-f-fc {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 120px;
    position: relative;
    display: flex;
    margin: 40px 0;
}

.second-f-fc h1 {
    font-size: 100px;
    color: #FFFFFF;
    font-family: "BNR";
    line-height: 100px;
    font-weight: 400;
}

.second-f-fc img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* animation: spin 7s linear infinite alternate; */
}

.second-s-fc-button {
    border: none;
    border: 2px solid rgba(35, 35, 35, 1);
    border-radius: 40px;
    height: 60px;
    padding: 18px 130px 18px 130px;
    color: #262626;
    font-size: 24px;
    font-family: "ISB";
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #FFFFFF;
    transition: all 0.5s ease;
}

.second-s-fc-button:hover {
    background-color: #dadada;
    box-shadow: 0px 5px 10px 0px rgba(16, 24, 40, 0.5);
    transform: translateY(-10px);
}

.second-s-fc-sbutton {
    border: none;
    border: 1px solid rgba(35, 35, 35, 1);
    border-radius: 40px;
    height: 60px;
    padding: 18px 130px 18px 130px;
    color: #FFFFFF;
    font-size: 24px;
    font-family: "ISB";
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(45, 45, 45, 1);
    cursor: pointer;
    transition: all 0.5s ease;

}


.second-s-fc-sbutton:hover {
    border: 1px solid rgba(35, 35, 35, 1);
    box-shadow: 0px 5px 10px 0px rgba(16, 24, 40, 0.5);
    transform: translateY(-10px);
}


.second-s-fc {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.second-s-fc h2 {
    font-family: "BNR";
    font-weight: 400;
    font-size: 52px;
    color: #262626;
}

.second-s-fc p {
    font-size: 24px;
    font-weight: 400;
    font-family: "NL";
    font-style: italic;
    margin: 20px 0px 40px 0px;

}

.second-s-fc section {
    display: flex;
    justify-content: space-between;
}

/* next */


.second-component {
    display: flex;
    justify-content: space-between;
    padding: 60px;
    padding-top: 20px;
    overflow: hidden;
    gap: 40px;
}


.second-component p {
    font-size: 32px;
    font-weight: 100;
    font-family: "NL";
    font-style: italic;
    line-height: 40px;
    max-width: 700px;
    /* animation: slideInFromBottom 3s ease-out; */
    opacity: 0;
    animation-fill-mode: forwards;
}


.second-component h1 {
    font-size: 160px;
    color: #262626;
    font-family: "BNR";
    line-height: 150px;
    font-weight: 400;
    /* animation: slideInFromTop 3s ease-out; */
    opacity: 0;
    animation-fill-mode: forwards;
}


.second-component p,
.second-component h1 {
    opacity: 0;
}


.animate-on-slidebottom,
.animate-on-slidebottom-fast,
.animate-on-slidebottom-slow {
    animation: slideInFromBottom 3s ease-out;
}


.animate-on-slidetop {
    animation: slideInFromTop 3s ease-out;
}

.animate-on-dropdown,
.circle-back {
    animation: dropDown 1s ease-out;
}

.circle-back img,
.dots-back img {
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: -1;

}

.dots-back img {
    opacity: 0.1;
}

.circle-back img {
    top: 100px;
    left: 200px;
    opacity: 0.2;
}


.animate-on-onesec {
    animation: dropDown 2s ease-out;
}

.animate-on-godowntwosec {
    animation: dropDown 2s ease-out;
}

.animate-on-godowntrisec {
    animation: dropDown 1s ease-out;
}

.animate-on-h1 {
    animation: fromRight 3s ease-out;
}

.animate-on-p {
    animation: fromLeft 2s ease-out;
}


.animate-on-godownfousec {
    animation: dropDown 3s ease-out;
}

.second-component p.animate-on,
.second-component h1.animate-on {
    opacity: 1;
}

/* next */

.third-component {
    display: flex;
    padding: 60px;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
    justify-content: space-around;
}

.third-component section {
    padding: 30px 60px 30px 60px;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #1A3F6B40;
    height: 300px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.opacity-none {
    opacity: 0;
}

.third-component p {
    font-size: 32px;
    font-weight: 100;
    font-family: "NL";
    line-height: 40px;
    max-width: 660px;
    display: flex;
    align-items: center;
    font-style: italic;
}



.third-component h1 {
    font-size: 160px;
    color: #262626;
    font-family: "BNR";
    font-weight: 400;
}

.third-component span {
    font-size: 30px;
    font-family: "IR";
    color: #262626;
    font-weight: 300;

}

/* next */


.fourth-component {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
    margin: 0 60px;
    position: relative;
    overflow: hidden;
}


.fourth-back {
    position: absolute;
    left: 0;
    height: 100%;
    user-select: none;
    width: 100%;
}

.foutht-stat {
    position: absolute;
    right: 20px;
    user-select: none;
    bottom: 20px;
    z-index: 1;
}

.fourth-component p {
    font-size: 32px;
    font-weight: 100;
    font-family: "NL";
    font-style: italic;
    line-height: 40px;
    max-width: 700px;
    z-index: 10;
    position: relative;
    padding: 60px;
}


.fourth-component h1 {
    font-size: 160px;
    color: #262626;
    font-family: "BNR";
    line-height: 150px;
    font-weight: 400;
    z-index: 10;
    padding: 60px;
}


/* next */

.partners-component {
    padding: 60px;
    overflow: hidden;
    padding-top: 120px;
}


.partners-component h1 {
    font-size: 160px;
    color: #262626;
    font-weight: 400;
    font-family: "BNR";
}

.partners-component section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partners-component section img:not(:last-child) {
    margin-right: 80px;
}




/* next */



.news-component {
    position: relative;
    padding-left: 60px;
    z-index: 10;
    padding-top: 100px;
}

.news-component h1 {
    color: #262626;
    font-size: 160px;
    font-weight: 400;
    font-family: "BNR";
}

.keen-slider {
    overflow: hidden;
}

.keen-slider__slide {
    min-width: calc((100% / 2.5) - 10px);
    box-sizing: border-box;
    padding-right: 10px;
}

.news-list {
    border-radius: 30px;
    padding: 40px 20px;
    margin-bottom: 10px;
}

.news-section-one {
    display: flex;
    flex-direction: column;
}

.news-section-two {
    display: flex;
    justify-content: space-between;
}



.news-section-one img {
    border-radius: 14px;
    padding: 0 20px;
}

.news-list div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.news-list div img {
    height: 65px;
}

.news-list section h2 {
    font-size: 32px;
    font-weight: 700;
    font-family: "IR";
    margin-top: 20px;
}

.news-list p {
    font-weight: 300;
    font-size: 24px;
    font-family: "NL";
    font-style: italic;
    margin-bottom: 20px;
}

.news-list section p {
    color: #26262680;
    font-weight: 400;
    font-family: "IR";
    font-size: 15px;
    margin-bottom: 0;
    height: unset;
}

.news-list section span {
    font-size: 16px;
    color: #E92F30;
    font-weight: 400;
    font-family: "IR";
}

.news-section-one {
    margin-bottom: 50px;
}

.arrow-section {
    margin-top: 80px;
    position: relative;
    z-index: 10;
    padding: 0px 60px;
    display: none;

}

.arrow-section img {
    cursor: pointer;
}

.arrow-section img:last-child {
    margin-left: 20px;
    margin-top: 1px;
}

.arrow-section h3 {
    color: #262626;
    font-size: 24px;
    font-family: "IB";
    font-weight: 600;
    border-bottom: 2px solid #1C4A31;
    cursor: pointer;
}


/* next */

.project-component {
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    padding-top: 120px;
    gap: 20px;
    justify-content: space-around;
}

.first-project-component {
    background-color: rgba(245, 255, 221, 1);
    border-radius: 20px;
    max-width: 760px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 310px;
}

.first-project-component div {
    display: flex;
    justify-content: flex-end;
}

.first-project-component h1 {
    font-size: 160px;
    color: rgba(38, 38, 38, 1);
    font-family: "BNR";
}

.first-project-component p {
    font-size: 32px;
    font-weight: 100;
    color: rgba(38, 38, 38, 1);
    font-style: italic;
    font-family: "NL";
    margin-bottom: 20px;
}


.first-project-component button {
    border: none;
    cursor: pointer;
    font-size: 24px;
    font-family: "IR";
    background-color: transparent;
    color: rgba(38, 38, 38, 1);
    border-bottom: 2px solid rgba(28, 74, 49, 1);
}


.second-project-component {
    max-width: 950px;
}



.second-project-component-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

}


.real-estate-component {
    border-radius: 20px;
    background-color: rgba(28, 74, 49, 1);
    padding: 20px;
    position: relative;
    overflow: hidden;
    min-width: 350px;
}

.real-estate-component h1 {
    color: rgba(243, 243, 243, 1);
    font-family: "BNR";
    font-weight: 400;
    font-size: 80px;
    position: relative;
    margin-bottom: 20px
}

.real-estate-component h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -14px;
    height: 14px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(235, 255, 187, 1);
}

.real-estate-component h2 {
    color: rgba(243, 243, 243, 1);
    font-weight: 100;
    font-style: italic;
    font-size: 32px;
    font-family: "NL";
    margin: 40px 0;
}

.real-estate-component div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.real-estate-component p {
    color: rgba(45, 114, 76, 1);
    font-size: 160px;
    font-weight: 900;
    font-family: "IB";
}

.overlay-real-estate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.15;
    width: 100%;
    height: 100%;
}

.users-component {
    border-radius: 20px;
    background-color: rgba(74, 74, 74, 1);
    padding: 20px;
    overflow: hidden;
    min-width: 500px;
}

.users-component h1 {
    color: rgba(243, 243, 243, 1);
    font-family: "BNR";
    font-weight: 400;
    font-size: 160px;
    position: relative;
    margin-bottom: 20px
}

.users-component div {
    font-weight: 100;
    font-size: 32px;
    font-style: italic;
    color: rgba(243, 243, 243, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.users-component div p {
    max-width: 232px;
    margin-bottom: 10px;
    font-family: "NL";
}

/* next */

.fifth-component {
    display: flex;
    padding: 60px;
    padding-top: 100px;
    height: 800px;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
    justify-content: space-between;
}

.fifth-component section {
    max-width: 550px;
    display: flex;
    flex-direction: column;

}



.fifth-component section h1 {
    font-weight: 400;
    font-size: 80px;
    color: #262626;
    font-family: "BNR";
    position: relative;
    margin-bottom: 20px;
}

.fifth-component section p {
    font-size: 32px;
    font-family: "NL";
    font-weight: 100;
    color: #262626;
    font-style: italic;
}

.fifth-section-two {
    justify-content: center;
}

.fifth-section-thr {
    justify-content: flex-end;
}




.fifth-component section h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -14px;
    height: 14px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.fifth-section-one h1::after {

    background-color: rgba(28, 74, 49, 1);
}

.fifth-section-two h1::after {
    background-color: rgba(235, 255, 187, 1);
}

.fifth-section-thr h1::after {
    background-color: rgba(74, 74, 74, 1);
}



/* next */

.foter-component {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 60px;
    flex-wrap: wrap;
}

.copyrating {
    font-family: "IR";
    font-weight: 300;
    margin-left: 60px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);

}

.foter-component p {
    font-size: 24px;
    font-weight: 600;
    color: rgba(38, 38, 38, 1);
    font-family: "IR";
    margin-bottom: 20px;
}

.foter-component h1 {
    color: rgba(38, 38, 38, 1);
    font-size: 52px;
    font-weight: 400;
    font-family: "BNR";
    margin: 20px 0;
}


.web-links {
    display: flex;
    flex-direction: column;
}

.web-links a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "IR";
    color: rgba(38, 38, 38, 1);
    cursor: pointer;
}

.web-links a:not(:last-child) {
    margin-bottom: 15px;
}

.social-links {
    display: flex;
}

.social-links a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    font-family: "DG";
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.social-links a:not(:last-child) {
    margin-right: 30px;
}

.footer-links {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 80px;
}

/* next */


.input-foter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(38, 38, 38, 1);
    padding-bottom: 5px;
}

.input-foter input {
    width: 100%;
    border: none;
    color: rgba(102, 102, 102, 1);
    font-weight: 400;
    font-size: 16px;
    font-family: "IR";
    line-height: 20px;
    padding: 10px;
}


.input-foter img {
    cursor: pointer;
}

/* next */

.lines-component div {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.lines-component img {
    animation: slideDown 3s ease-out;
}


.lines-component img:nth-child(1) {
    height: 150px;
}

.lines-component img:nth-child(2) {
    height: 250px;
}

.lines-component img:nth-child(3) {
    height: 350px;
}

.lines-component {
    position: absolute;
    right: 200px;
    top: 0px;
    z-index: -1;
}



/* next */

.lich-kabinet-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    border-radius: 20px;
    background-color: #F6F6FB;
    margin: 10px 0;
    align-items: center;
}

.header-section-one {
    display: flex;
    align-items: center;
}

.header-section-one div {
    display: flex;
    align-items: center;
    width: 300px;
}

.header-section-one div h2 {
    font-size: 25px;
    font-weight: 400;
    color: rgba(38, 38, 38, 1);
    font-family: "RR";
    margin-left: 10px;
}

.overwiev-header {
    font-weight: 600;
    font-family: "IR";
    font-size: 22px;
    color: rgba(38, 38, 38, 1);
}

.header-section-two {
    display: flex;
    align-items: center;
    position: relative;
}

.header-section-two input {
    border: none;
    font-size: 14px;
    color: rgba(139, 163, 203, 1);
    font-family: "IR";
    background-color: rgba(245, 247, 250, 1);
    height: 30px;
}

.header-section-two input:focus {
    outline: none;
}

.header-section-div-one {
    padding: 10px 20px;
    background-color: rgba(245, 247, 250, 1);
    border-radius: 40px;
    color: rgba(139, 163, 203, 1);
    margin-right: 20px;
    display: flex;
    align-items: center;

}

.header-section-div-one img {
    margin-right: 10px;
    user-select: none;
}

.header-section-div-two {
    display: flex;
    gap: 20px;
    align-items: center;
}

.header-section-div-two img {
    cursor: pointer;
}

.lich-kabinet {
    padding: 20px;
}


.my-profile-component {
    background-color: #F6F6FB;
    box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
    border-radius: 0px 20px 20px 0px;
    padding: 40px 20px;
    min-width: 260px;
    width: 290px;
}

.my-profile-section-one {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-profile-component h2 {
    font-size: 18px;
    color: rgba(38, 38, 38, 1);
    font-weight: 600;
    font-family: "IR";
}

.my-profile-component h1 {
    font-size: 22px;
    color: rgba(38, 38, 38, 1);
    font-weight: 600;
    font-family: "IR";
}



.my-profile-component span {
    font-size: 40px;
    background-color: #1AC5CD;
    padding: 20px;
    border-radius: 50%;
}

.my-profile-component img {
    margin: 20px 0px;
}


.my-profile-section-one .initials {
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #18643B;
    color: #FFFFFF;
    font-family: "IR";
    font-weight: 600;
    font-size: 22px;
    margin: 20px 0;

}

.profile-avatar .initials {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #18643B;
    color: #FFFFFF;
    font-family: "IR";
    font-weight: 600;
    font-size: 22px;
    margin: 20px 0;
}

.my-profile-section-one a {
    text-decoration: none;
    color: rgba(175, 175, 175, 1);
    font-size: 14px;
    font-weight: 400;
    font-family: "IR";
    cursor: pointer;
    margin-top: 10px;
}



.link {
    text-decoration: none;
    color: rgba(175, 175, 175, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    cursor: pointer;
    display: flex;
    align-items: center;

    position: relative;
}

.my-profile-section-two {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.my-profile-section-two a {
    padding-left: 40px;
}

.my-profile-section-two img {
    margin-right: 20px;
}


.link-active {
    color: #26BB6A;
}

.link-active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #26BB6A;
    border-radius: 0 20px 20px 0;
}

/* next */


.my-profile-main {
    display: flex;

}



.my-statistic-account {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.my-statistic-account section {
    height: 120px;
    width: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F6FB;
    border-radius: 20px;

}


.my-statistic-account section img {
    margin-right: 10px;
}

.my-statistic-account section h2 {
    color: rgba(175, 175, 175, 1);
    font-size: 14px;
    font-weight: 400;
    font-family: "IR";
}

.my-statistic-account section p {
    margin-top: 10px;
    color: rgba(38, 38, 38, 1);
    font-size: 25px;
    font-weight: 600;
    font-family: "IR";
}

.h1-statistic-account {
    color: rgba(38, 38, 38, 1);
    font-weight: 600;
    font-size: 22px;
    font-family: "IR";
    margin-bottom: 10px;
}

.my-statistic-account-div-two,
.my-statistic-account-div-one {
    display: flex;
    gap: 10px;
}


.my-statistic-account-div-one section div,
.my-statistic-account-div-two section div {
    width: 80px;
}


/* next */



.send-money-component img {
    user-select: none;
}

.payment-method-selection {
    min-width: 280px;
}


.payment-method-selection img {
    height: 10px;
    margin-right: 5px;

}

.payment-method-selection main {
    font-size: 16px;
    font-weight: 500;
    color: rgba(20, 23, 54, 1);
    font-family: "IR";
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.payment-method-selection p {
    color: rgba(175, 175, 175, 1);
}



.payment-method-selection input {
    width: 230px;
    border: none;
    border-radius: 20px;
}


.payment-method-selection section {
    display: flex;
    align-items: center;
}


.send-money-amount {
    background-color: #F6F6FB;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
}

.send-money-amount h2 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(175, 175, 175, 1);
    font-family: "IR";
}

.send-money-amount main {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.send-money-amount main input {
    width: 100px;
    border: none;
    font-size: 22px;
    color: rgba(38, 38, 38, 1);
    font-family: "IR";
    font-weight: 600;
    background-color: #F6F6FB;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.send-money-amount main input:focus {
    border: none;
    outline: none;
}

.send-money-amount section span {
    font-size: 22px;
    font-weight: 400;
    color: rgba(175, 175, 175, 1);
    font-family: "IR";
    margin-right: 5px;
}


.send-money-button {
    border: none;
    border-radius: 16px;
    background-color: transparent;
    border: 2px solid #26BB6A;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    color: #262626;

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}


/* next */

.balance-history-component {
    max-width: 620px;
}


.balance-history-component div {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px 0px #6064621A;

}

.balance-history-component img {
    width: 100%;
    min-width: 450px
}

.top-statistic-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-statistic-account a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    font-family: "IR";
    color: rgba(38, 38, 38, 1);
}

/* next */


.card-my-account {
    background: linear-gradient(230.62deg, #6AF5AA -69.78%, #26BB6A 21.18%, #18643B 93.27%);
    border-radius: 25px 25px 0 0;
    padding: 20px;
    width: 310px;
    padding-bottom: 0;
}



.card-secondarymy-account {
    background: transparent;
    border-radius: 25px 25px 0 0;
    padding: 20px;
    width: 310px;
    padding-bottom: 0;
}

.card-top {
    display: flex;
    justify-content: space-between;
}


.card-top h2 {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    font-family: "IR";
}


.card-top p {
    font-size: 20px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    font-family: "IR";
}


.card-middle {
    display: flex;
    margin-top: 45px;
}

.card-middle h1 {
    font-size: 65px;
    color: #FFFFFF;
    font-family: "IB";
    font-weight: 600;
    z-index: 2;

}


.lumina-absolute {
    position: absolute;
    top: 38px;
    left: 0px;
    width: 100%;
    z-index: 1;
}

.cart-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 0 0 25px 25px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
    width: 310px;
}


.cart-bottom h1 {
    color: #FFFFFFB2;
    font-family: "IR";
    font-weight: 400;
    font-size: 14px;
}

.cart-bottom h2 {
    color: #FFFFFFB2;
    font-family: "IR";
    font-weight: 400;
    font-size: 15px;
}

.cart-bottom-bottom {
    background: linear-gradient(230.62deg, #6AF5AA -69.78%, #26BB6A 21.18%, #18643B 93.27%);
    border-radius: 0 0 25px 25px;
    width: 350px;
}


.cart-bottomsecond-bottom {
    background: transparent;
    border-radius: 0 0 25px 25px;
    width: 350px;
    border-top: 1px solid #262626;
}

.cart-bottomsecond-bottom .cart-bottom h2 {
    color: #262626;
}

.cart-bottomsecond-bottom .cart-bottom h1 {
    color: #A1A4A7;
}


.card-secondarymy-account .card-middle h1 {
    color: #262626;
}

.card-secondarymy-account .card-top h2 {
    color: #262626;
}

/* next */


.section-last-transactions {
    background-color: #F6F6FB;
    border-radius: 20px;
    padding: 20px;
    min-width: 680px;
}


.transaction-discription {
    display: flex;
}

.no-transactions-message {
    font-size: 20px;
    font-family: "IR";
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.transaction-info h2 {
    font-size: 16px;
    color: rgba(38, 38, 38, 1);
    font-weight: 500;
    font-family: "IR";
}

.transaction-info p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(113, 142, 191, 1);
    font-family: "IR";
}

.transaction-discription p {
    font-size: 16px;
    font-weight: 500;
    color: rgba(113, 142, 191, 1);
    font-family: "IR";
    margin-right: 20px;
    width: 80px;
}

.transaction-plus {
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    color: rgba(22, 219, 170, 1);
}

.transaction-discription span {
    width: 80px;
    text-align: end;
}

.transaction-minus {
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    color: rgba(246, 78, 96, 1);
}

.transaction-info {
    margin: 0 20px;
    width: 150px;
}

.transaction-div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


/* next */


.investment-div {
    display: flex;
    background-color: #F6F6FB;
    align-items: center;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 10px;

}


.investment-div h2 {
    color: rgba(38, 38, 38, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
}

.investment-div p {
    color: #A1A4A7;
    font-weight: 400;
    font-family: "IR";
    font-size: 14px;
}

.investment-div img {
    margin-right: 20px;
}

.investment-plus {
    font-weight: 500;
    font-family: "IR";
    font-size: 16px;
    color: rgba(25, 196, 204, 1);
}




.investment-minus {
    font-weight: 500;
    font-family: "IR";
    font-size: 16px;
    color: rgba(246, 78, 96, 1);
}


.investment-div section {
    width: 180px;

}

.investment-div section:last-child {
    width: 100px;
}



/* next */
.expense-statistic-component img {
    user-select: none;
    width: 500px;
}



/* next */

.messages-component {
    border-radius: 75px 0px 0px 75px;
    background-color: #FFFFFF;
    padding: 80px 40px;
    width: 260px;
}

.messages-component-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(197, 209, 229, 1);
    margin-bottom: 20px;
}

.messages-component-header h2 {
    font-weight: 600;
    font-size: 22px;
    font-family: "IR";
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.not-selected-messages-comp {
    color: rgba(139, 163, 203, 0.4);
}



.selected-messages-comp {

    color: rgba(38, 38, 38, 1);
}


.messages-component div {
    background-color: rgba(245, 247, 250, 1);
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 20px;
    cursor: pointer;
}



.messages-component div section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.messages-component div section h2 {

    font-size: 16px;
    font-weight: 500;
    color: rgba(38, 38, 38, 1);
    font-family: "IR";
}

.messages-component div section img:not(:last-child) {

    margin-right: 10px;
}

.messages-component div section p {
    font-size: 14px;
    font-weight: 400;
    font-family: "IR";
    color: rgba(175, 175, 175, 1);
}

.messages-component div span {
    color: rgba(139, 163, 203, 1);
    font-size: 14px;
    font-weight: 400;
    font-family: "IR";
    text-wrap: nowrap;
    overflow: hidden;
}


.top-three-lich-kabinet {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

/* next */


.my-expense-component img {
    box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
}


.h1-section-recent {
    display: flex;
    padding-left: 10px;
    gap: 40px;
    text-wrap: nowrap;
}


.not-selected {
    font-size: 16px;
    font-weight: 500;
    color: rgba(175, 175, 175, 1);
    font-family: "IR";
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 0 10px;
}

.selected {
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    cursor: pointer;
    color: #26BB6A;
    position: relative;
    padding: 0 10px;
}

.selected::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: #26BB6A;
    position: absolute;
    bottom: -10px;
    border-radius: 20px 20px 0 0;
    left: 0;
}



.recent-transactions {
    width: 100%;
}

.calendar-section-transaction {
    display: flex;
    align-items: center;
    box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #A1A4A7;
    font-family: "IR";
    justify-content: space-between;
    width: 160px;
    cursor: pointer;

}

.calendar-section-transaction div {
    display: flex;
    align-items: center;
}

.calendar-section-transaction p {
    margin-left: 5px;
}

.main-transaction-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* next */


.all-transactions-recent {
    background-color: rgba(255, 255, 255, 1);
    padding: 20px 40px;
    border-radius: 20px;
    margin-top: 20px;
}

.h2-section-top-all-transaction {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}



.h2-section-top-all-transaction h2 {
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    color: #A1A4A7;
    text-align: center;
}

.info-transaction-div {
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid rgba(230, 239, 245, 1);
    justify-content: space-between;
    margin-top: 10px;
}

.info-transaction-div h2 {
    font-size: 16px;
    color: rgba(38, 38, 38, 1);
    font-weight: 400;
    font-family: "IR";
    text-wrap: nowrap;
    overflow: hidden;
}

.info-transaction-div button {
    border: none;
    background-color: transparent;
    border: 1px solid rgba(38, 38, 38, 1);
    border-radius: 50px;
    color: rgba(38, 38, 38, 1);
    font-weight: 400;
    font-size: 15px;
    font-family: "IR";
    padding: 10px;
    cursor: pointer;
}

.info-transaction-div img {
    margin-right: 20px;
}

/* next */

.receipt-transaction {

    width: 100px;
}


.info-transaction-div section {
    display: flex;
    align-items: center;

}

.description-transaction {
    width: 200px;
}

.amount-transaction,
.type-transaction,
.card-transaction {
    width: 80px;
}

.amount-transaction {
    text-align: center;
}

.transactionid-transaction {
    width: 120px;
}

.date-transaction {
    width: 140px;
}

/* next */


.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
}

.pagination-container-buttons {
    border: none;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    font-family: "IR";
    border-radius: 8px;
}

.un-active-page {
    color: #26BB6A;
    background-color: transparent;
}

.active-page {
    color: rgba(255, 255, 255, 1);
    background-color: #26BB6A;
}


.pagination-container-buttons-slide {
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 15px;
    color: #26BB6A;
    font-family: "IR";
    cursor: pointer;
    display: flex;
    align-items: center;
}

.pagination-container-buttons-slide img {
    margin: 0 10px;
}




/* next */


.h2-section-div-trending {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(244, 245, 247, 1);
    padding-bottom: 10px;
}

.h2-section-div-trending h2 {
    font-size: 16px;
    font-family: "IR";
    font-weight: 500;
    color: rgba(113, 142, 191, 1);
}


.trending-stock-company {
    font-size: 16px;
    font-family: "IR";
    display: flex;
    justify-content: space-between;
}

.treding-stock-companys {
    background-color: #FAFAFA;
    border-radius: 25px;
    padding: 20px;
    width: 400px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 10px 60px 0px #E2ECF980;

}

.h2-sl-no-ts {
    width: 50px;
}

.h2-name-ts {
    width: 100px;
}

.h2-price-ts,
.h2-return-ts {
    width: 60px;
}


.trending-stock-company h3 {
    font-weight: 400;
    font-size: 16px;
    color: rgba(38, 38, 38, 1);
    font-family: "IR";
    text-wrap: nowrap;
    overflow: hidden;
}

.investments-info-calculated h1 {

    font-weight: 400;
    font-size: 14px;
    color: rgba(113, 142, 191, 1);
    font-family: "IR";
}

.investments-info-calculated h2 {
    font-weight: 600;
    font-size: 20px;
    font-family: "IR";
    color: rgba(38, 38, 38, 1);
}

.investments-info-calculated img {
    margin-right: 20px;
}

.investments-info-calculated {
    padding: 20px 60px;
    background-color: #F6F6FB;
    border-radius: 20px;
    display: flex;
    height: 50px;
    align-items: center;
}

.investments-info-calculated:not(:first-child) {
    margin-top: 10px;
}



.invest-new-project {
    border: none;
    background-color: #26BB6A;
    padding: 10px 40px;
    border-radius: 16px;
    font-size: 16px;
    font-family: "IR";
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    cursor: pointer;
    max-height: 45px;
}

/* next */


.settings-form-component {
    padding: 40px;
    background-color: #F6F6FB;
    border-radius: 25px;
    max-width: 985px;
}

.settings-header-h1 {
    display: flex;
    margin-bottom: 40px;
    gap: 20px;
    text-wrap: nowrap;
}

.settings-header-h1 .inactive-tab,
.active-tab {
    color: #A1A4A7;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    font-family: "IR";
    padding: 0 10px;
    cursor: pointer;
}

.active-tab {
    color: #26BB6A
}

.settings-header-h1 .active-tab::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: #26BB6A;
    position: absolute;
    bottom: -10px;
    border-radius: 20px 20px 0 0;
    left: 0;
    transition: all 1s ease-in-out;
}

.settings-header-h1 .inactive-tab::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #26BB6A;
    position: absolute;
    bottom: -10px;
    border-radius: 20px 20px 0 0;
    left: 0;
    transition: all 1s ease-in-out;
    opacity: 0;
}

.transition-wrapper {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.transition-wrapper.visible {
    opacity: 1;
}


.edit-profile-settings {
    display: flex;
    gap: 20px;
}

.profile-avatar {
    position: relative;
    display: inline-block;
}

.avatar-settings {
    display: block;
    height: 100px;
}

.edit-pen {
    position: absolute;
    top: 85px;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}


.profile-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}


.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-size: 16px;
    font-weight: 400;
    color: rgba(38, 38, 38, 1);
    font-family: "IR";
    margin-bottom: 10px;
}

.form-group input {
    border: none;
    border: 1px solid rgba(223, 234, 242, 1);
    border-radius: 15px;
    width: 400px;
    padding: 10px;
    color: rgba(113, 142, 191, 1);
    font-weight: 400;
    font-size: 15px;
}

.form-group input::placeholder {
    color: #26BB6A;
    font-size: 15px;
    font-weight: 400;

}

.button-save-settings {
    width: 200px;
    border: none;
    background-color: #26BB6A;
    border-radius: 15px;
    font-size: 16px;
    font-family: "IR";
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    height: 50px;
    cursor: pointer;
}

.preferences-settings h2,
.security-settings h2 {
    font-family: "IR";
    color: #26BB6A;
}

.center-home-page {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.lines-header {
    display: none;
}


.overlay-header {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1000;
}

.cross-header {
    position: absolute;
    top: 20px;
    right: 20px;
}


.overlay-header section {
    padding: 20px;
    padding-top: 60px;
}

.overlay-header section a {
    font-size: 24px;
    font-family: "NR";
    font-weight: 400;
    font-style: italic;
    cursor: pointer;
}

.overlay-header section p {
    margin-top: 20px;
}


.overlay-header section button {
    position: absolute;
    border: none;
    border: 2px solid rgba(35, 35, 35, 1);
    height: 50px;
    border-radius: 40px;
    font-size: 24px;
    color: rgba(28, 28, 28, 1);
    font-weight: 600;
    font-family: "IR";
    bottom: 40px;
    left: 20px;
    right: 20px;
    cursor: pointer;
}


.no-scroll {
    overflow: hidden;
}


/* next */


.sponsor-section {
    display: flex;
    align-items: center;
    gap: 20px;
}

.sponsor-section div {
    border-radius: 40px;
    background-color: #FAFAFA;
    padding: 10px;
    font-family: "IR";
    font-weight: 400;
    font-size: 14px;
}


.broker-section {
    background-color: #D9D9D9;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.broker-section span {
    color: #E1E1E1;
    font-size: 160px;
    font-weight: 900;
    font-family: "IB";
    z-index: 1;
}

.broker-section p {
    font-size: 32px;
    font-family: "NL";
    font-style: italic;
    max-width: 450px;
    font-weight: 100;
}

.broker-section div {
    display: flex;
    align-items: center;
}

.button-news {
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.battery-broker {
    position: absolute;
    top: 60px;
    right: 50px;
}



.broker-section h1 {
    color: #262626;
    font-family: "BNR";
    font-weight: 400;
    font-size: 80px;
    position: relative;
    margin-bottom: 20px;
    max-width: 400px;
    z-index: 1;
}

.broker-section h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -14px;
    height: 14px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(235, 255, 187, 1);
}

/* next */


.news-list div img {
    cursor: pointer;
}


/* next */


.my-profile-lvl {
    display: flex;
    align-items: center;
    box-shadow: 0px 10px 60px 0px #E2ECF980;
    background-color: #FAFAFA;
    border-radius: 50px;
    padding: 10px 30px;
    justify-content: space-between;
    margin: 20px 10px;

}

.my-profile-lvl h1 {
    font-weight: 500;
    font-size: 16px;
    color: #262626;
    font-family: "ISB";
}

.my-profile-lvl p {
    font-size: 14px;
    color: #A1A4A7;
    font-weight: 400;
    font-family: "IR";
}

.my-profile-lvl img {
    margin: 0;
}


.settings-form-gray-text {
    max-width: 400px;
    color: #A1A4A7;
    font-weight: 400;
    font-size: 14px;
    font-family: "IR";
    margin-top: 10px;
}

.h2-top-up-balance {
    display: flex;
    gap: 20px;
}

.h2-top-up-balance-h2 {
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    min-width: 350px;
    padding-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.h2-top-up-balance-h2::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: #A1A4A7;
}

.top-up-balance-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    min-width: 350px;
}

.top-up-balance-inputs div {

    display: flex;
    flex-direction: column;
    max-width: 350px;
}

.top-up-balance-inputs div p {
    color: #A1A4A7;
    font-size: 12px;
    font-weight: 400;
    font-family: "IR";
    margin-top: 10px;
}

.top-up-balance-inputs div label {
    color: #A1A4A7;
    font-weight: 400;
    font-size: 14px;
    font-family: "IR";
    margin-bottom: 5px;
}

.top-up-balance-inputs input,
.top-up-balance-inputs select {
    font-size: 16px;
    font-weight: 400;
    font-family: "IR";
    border: 1px solid #A1A4A7;
    padding: 10px;
    border-radius: 10px;
    color: #262626;
}

.top-up-balance-inputs option {
    font-size: 16px;
    font-weight: 400;
    font-family: "IR";
}


.top-up-balance-inputs button {
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-family: "IR";
    color: #FFFFFF;
    background-color: #26BB6A;
    border-radius: 10px;
    max-width: 350px;
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;
}


.active-step {
    color: #26BB6A;
    position: relative;
}

.active-step::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #26BB6A;
    border-radius: 20px 20px 0 0;
}

.top-up-fieldset {
    border: none;
}


.my-accounts-cards {
    display: flex;
    gap: 20px;
}


.center-my-transactions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
    align-content: flex-start;
}

.my-account-component {
    overflow: hidden;
}

.lines-heade-lich {
    display: none
}


.overlay-lich-cabinet {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    z-index: 1000;
    padding: 50px 0;
}

.exit-overlay-header {
    height: 30px;
    width: 30px;
}

.trending-stocks-display {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.friends-send-money {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.friends-send-money p {
    font-size: 14px;
    font-weight: 400;
    font-family: "IR";
    color: #262626;
}

.friends-send-money div {
    display: flex;
    align-items: center;
}

.friends-send-money div img {
    margin-right: 10px;
}

.friends-send-money img:nth-child(2) {
    user-select: all;
    cursor: pointer;
}



.referal-link-component {
    background-color: #F6F6FB;
    border-radius: 15px;
}

.referal-link-component {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: "IR";
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 20px;
    display: none;
    cursor: pointer;
}

/* next */


.deposit-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}



.deposit-component h2 {
    font-size: 16px;
    font-weight: 400;
    font-family: "IR";
    color: #262626;
    max-width: 1200px;
}


.deposit-component h1 {
    font-size: 22px;
    font-weight: 600;
    font-family: "IR";
    color: #262626;
    max-width: 1200px;
}


.deposit-component main {
    background-color: #F6F6FB;
    border-radius: 26px;
    display: flex;
    padding: 60px 40px;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 60px;
}


.deposit-plan {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px -2px #10182808;
    box-shadow: 0px 12px 16px -4px #10182814;
    border-radius: 20px;
    width: 260px;
    height: 420px;
    padding: 20px;
    font-family: "IR";

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease;
}

.deposit-plan p:nth-child(1) {
    color: #26BB6A;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    background-color: #F5FFDD;
    border-radius: 24px;
    width: min-content
}


.deposit-plan p:nth-child(2) {
    font-size: 16px;
    color: #829AB1;
}

.deposit-plan p:nth-child(3) {
    color: #A1A4A7;
    font-size: 13px;
    height: 100px;
}

.deposit-plan button {
    border: none;
    border: 2px solid #26BB6A;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 10px;
    border-radius: 15px;
    background-color: transparent;
    width: 100%;
    transition: all 0.5s ease;

    font-family: "IR";
    font-size: 15px;
    color: #262626;
    font-weight: 600;
    cursor: pointer;
}

.deposit-plan button:hover {
    background-color: #26BB6A;
    color: white;
}

.deposit-plan b {
    color: #262626;
    font-size: 42px;
    font-weight: 600;
    margin-right: 10px;
}

.deposit-description {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.deposit-description div {
    display: flex;
    align-items: center;
    color: #A1A4A7;
    font-size: 12px;
    font-weight: 400;
}

.deposit-plan div img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
}

.deposit-description hr {
    border: none;
    border-bottom: 1px solid #F6F6FB;
}

.deposit-plan:hover {
    transform: scale(1.2);
}




/* next */





.referal-program-h1 {
    display: flex;
    gap: 20px;
}

.referal-program-h1 h1 {
    font-size: 16px;
    color: #A1A4A7;
    font-family: "IR";
    font-weight: 400;
    text-wrap: nowrap;

}


.referal-program-h1 h1:nth-child(1),
.referal-user h2:nth-child(1) {
    width: 140px;
    min-width: 140px;
}

.referal-program-h1 h1:nth-child(2),
.referal-user h2:nth-child(2) {
    width: 130px;
    min-width: 70px;
}

.referal-program-h1 h1:nth-child(3),
.referal-user h2:nth-child(3) {
    width: 100px;
    min-width: 70px;
}

.referal-program-h1 h1:nth-child(4),
.referal-user h2:nth-child(4) {
    width: 120px;
    text-align: end;
    min-width: 120px;
}

.referal-program-h1 h1:nth-child(5),
.referal-user h2:nth-child(5) {
    width: 70px;
    text-align: end;
    min-width: 70px;
}

.referal-program-h1 h1:nth-child(6),
.referal-user h2:nth-child(6) {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    min-width: 70px;
}

.referal-user h2:nth-child(6) img {
    cursor: pointer;
}

.referal-program-section-one {
    padding: 20px 40px;
    background-color: #F6F6FB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: fit-content;
    max-height: 500px;
    justify-content: space-between;
}


.referal-user {
    display: flex;
    gap: 20px;
}

.referal-user h2 {
    font-size: 16px;
    font-weight: 400;
    font-family: "IR";
    color: #262626;
    text-wrap: nowrap;
}

.referal-program-section-two {
    display: flex;
    gap: 20px;
    flex-direction: column;
    max-width: 840px;
}

.referal-program-section-two main {
    padding: 15px 40px;
    border-radius: 20px;
    background-color: #F6F6FB;
    width: 355px;
    display: flex;
    align-items: center;
    height: 60px;
    gap: 20px;
}


.referal-program-section-two main div h2 {
    color: #A1A4A7;
    font-size: 14px;
    font-weight: 400;
    font-family: "IR";
}


.referal-program-section-two .referal-info img {
    margin-left: 10px;
}


.referal-program-section-two main div p {
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    font-family: "IR";
}


.referal-program-section-two .referal-info {
    justify-content: space-between;
}

.referal-program-section-two .referal-info section {
    display: flex;
    align-items: center;
}

.referal-program-section-two .referal-info section:nth-child(1) {
    flex-direction: column;
}




.referal-program-section-two main section p {
    color: #262626;
    font-size: 14px;
    font-family: "IR";
    font-weight: 500;
}

.referal-program-section-two main section span {
    color: #A1A4A7;
    font-size: 14px;
    font-family: "IR";
    font-weight: 500;
}

.referal-program-section-two main section b {
    color: #A1A4A7;
    font-size: 18px;
    font-weight: 700;
    font-family: "IR";
}



.referal-program-component {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
    align-content: flex-start;
}

.all-referals {
    display: flex;
    gap: 20px;
    flex-direction: column;
    max-height: 381px;
}



/* next */

.regestration-component {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.regestration-component main {
    border-radius: 40px;
    background-color: #FAFAFA;
    max-width: 700px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.regestration-component main section {
    max-width: 345px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.regestration-component main div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.regestration-component main h1 {
    font-size: 52px;
    font-weight: 400;
    color: #1C1C1C;
    font-family: "BNR";
}

.regestration-component .log-in-main main input {
    width: -webkit-fill-available;
    border: none;
    border-radius: 10px;
    border: 1px solid #A1A4A7;
    padding: 10px;
}

.regestration-component .log-in-main main section div input:nth-child(2) {
    margin-bottom: 10px;

}

.regestration-component main button {
    border: none;
    border-radius: 10px;
    background-color: #26BB6A;
    color: #FFFFFF;
    font-family: "IR";
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    padding: 10px;
}

.regestration-component-text-first {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    font-family: "NL";
    margin-top: 20px;
    font-style: italic;
}


.regestration-component-text-second {
    color: #A1A4A7;
    font-size: 10px;
    font-weight: 400;
    font-family: "IR";
}

.regestration-component-end-div {
    display: flex;
    align-items: flex-end;
}

.regestration-component-end-div a {
    text-decoration: none;
    color: #A1A4A7;
    font-family: "IR";
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid #26BB6A;
}

.regestration-component-end-div p {
    font-family: "NR";
    font-weight: 400;
    font-size: 20px;
    font-style: italic;
    margin-bottom: 20px;
}

.regestration-component-end-div span {
    color: #E21313;
}


.regestration-component main footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;
}

.regestration-component main footer a {
    text-decoration: none;
    color: #A1A4A7;
    font-size: 10px;
    font-weight: 400;
    font-family: "IR";
    cursor: default;
}


.registration-main input {
    border: none;
    border-radius: 10px;
    border: 1px solid #A1A4A7;
    padding: 10px;
}

.registration-main div div {
    display: flex;
    flex-direction: row;
}

.registration-main .regestration-component-end-div p {
    font-size: 16px;
}

.registration-main div div input {
    width: 50%;
}


.registration-main .section-checkbox-reg {
    display: flex;
    flex-direction: row;
}

.registration-main label {
    font-family: "IR";
    font-weight: 400;
    font-size: 10px;
    color: #A1A4A7;
}

.section-checkbox-reg input[type="checkbox"] {
    display: none;
}

.section-checkbox-reg label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.5;
}

.section-checkbox-reg label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border: 2px solid #26BB6A;
    background-color: white;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.section-checkbox-reg input[type="checkbox"]:checked+label:before {
    background-color: #26BB6A;
    border-color: #26BB6A;
    content: '\2713';
    color: white;
    text-align: center;
    line-height: 15px;
    border-radius: 4px;
}

.log-in-inputs input {
    border: none;
    border-radius: 10px;
    border: 1px solid #A1A4A7;
    padding: 10px;
}


.absolut-regestration-component {
    position: fixed;
    top: 20px;
    left: 30px;
    cursor: pointer;
}


/* next */


.infinity-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infinity-loading img {
    max-height: 500px;
    max-width: 500px;
    width: -webkit-fill-available;
}


.notifications-lich {
    width: 300px;
    box-shadow: 0px 10px 20px 0px #6064621A;
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}


.notifications-lich::after {
    content: '';
    position: absolute;
    top: -19px;
    right: 15px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255) transparent;
}

.notifications-lich div {
    background-color: #F6F6FB;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    font-family: "IR";
    font-size: 14px;
    border-radius: 6px 6px 0 0;
}

.notifications-lich .section-button {
    display: flex;
    justify-content: center;
}

.notifications-lich div img {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.notifications-lich div p {
    max-width: 235px;
}


.notifications-lich div h2 {
    font-size: 10px;
    color: #A1A4A7;
    font-family: "IR";
    font-weight: 400;
    margin-top: 20px;
}

.notifications-lich div span {
    color: #26BB6A;
}

.notifications-lich button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #26BB6A;
    font-size: 14px;
    font-family: "IR";
    font-weight: 600;
}





.notifications-dropdown {
    position: absolute;
    top: 80px;
    right: 140px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 1000;
    padding: 10px;
}

.notifications-dropdown::after {
    content: '';
    position: absolute;
    top: -19px;
    right: 15px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255) transparent;
}
@media screen and (max-width: 1820px) {
    .top-three-lich-kabinet {
        flex-wrap: wrap;
    }

    .notification-lich-kab{
        display: none;
    }
    .messages-component {
        display: none;
    }

    .third-component section:nth-child(4) {
        width: 388px;
    }

    .third-component p {
        max-width: 550px;
    }

    .third-component h1 {
        font-size: 120px;
    }


    .third-component section {
        padding: 30px;
    }


    .fifth-component section {
        max-width: 425px;
    }

    .fifth-component section h1 {
        font-size: 60px;
    }

    .fifth-component section p {

        font-size: 28px;
    }

    .fifth-component {
        height: 650px;
    }



    .real-estate-component,
    .users-component {
        min-width: 340px;
    }

    .back-footer-img {
        max-width: 600px;
    }


}

@media screen and (max-width: 1850px) {
    .first-project-component {
        max-width: 700px;
    }

}

@media screen and (max-width: 1620px) {

    .first-project-component {
        max-width: 550px;
    }


}

@media screen and (max-width: 1500px) {
    .my-accounts-cards {
        overflow: scroll;
    }

    .lines-component {
        right: 100px;
    }

    .second-project-component {
        max-width: 740px;
    }

    .third-component section {
        height: 250px;
        padding: 30px;
        flex: auto;
    }

    .third-component span {
        font-size: 20px;
    }

    .third-component h1 {
        font-size: 120px;
    }

    .fifth-component section {
        max-width: 400px;
    }


    .fifth-component section h1 {
        font-size: 60px;
    }

    .fifth-component section p {

        font-size: 28px;
    }

    .fifth-component {
        height: 650px;
    }

    .first-project-component {
        max-width: 500px;
    }

    .real-estate-component,
    .users-component {
        min-width: 320px;
    }

    .back-footer-img {
        max-width: 500px;
    }

    .third-component p {
        max-width: 550px;
    }


    .second-s-fc section {
        flex-direction: column;
        gap: 10px;
    }

    .second-s-fc {
        max-width: 500px;
    }

}

@media screen and (max-width: 1400px) {
    .first-project-component {
        max-width: unset;
    }

    .second-project-component {
        max-width: unset;
        width: 100%;
    }

    .real-estate-component,
    .users-component {
        width: -webkit-fill-available;
        margin-right: 0
    }

}


@media screen and (max-width: 1280px) {

    .fifth-component {
        height: unset;
    }

    .project-div {
        margin-bottom: 40px;
    }

    .fourth-component {
        flex-wrap: wrap;
    }

    .second-component {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .partners-component section {
        justify-content: unset;
    }

    .partners-logos {
        animation: marquee 10s linear infinite;
    }

    .h2-top-up-balance-h2,
    .top-up-fieldset:disabled {
        display: none;
    }


    .active-step {
        display: unset;
    }

    .referal-program-section-one {
        overflow: scroll;
    }

    .referal-program-h1 h1:nth-child(6),
    .referal-user h2:nth-child(6) {
        width: 100px;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
    }

    .referal-program-section-two {
        width: 100%;
    }

    .referal-program-section-two main {
        width: unset;
    }

    .first-component-div {
        justify-content: center;
    }

}


@media screen and (max-width: 1100px) {

    .my-profile-component,
    .my-profile-main {
        display: none;
    }




    .norification-header {
        display: unset;
    }

    .center-my-transactions {
        justify-content: center;
    }

    .header-section-one div {
        display: none;
    }

    .lines-heade-lich {
        display: unset;
    }

    .second-s-fc {
        max-width: unset;
    }

    .fifth-component section {
        max-width: unset;
    }

 
}

@media screen and (max-width: 1024px) {


    .third-component {
        justify-content: center;
        padding-top: 150px;
    }

    .project-component {
        justify-content: center;
    }

    .project-component main {
        justify-content: center;
    }

    .header-main section {
        display: none;
    }

    .sponsor-section {
        display: none;
    }

    .referal-link-component {
        display: flex;
    }

    .lines-header {
        display: unset;
    }

    .overlay-header {
        display: unset;
    }

    .fifth-component {
        justify-content: center;
    }

    .animate-on-slidebottom-fast {
        animation: fromLeft 4s ease-out;
    }

    .animate-on-slidebottom {
        animation: fromLeft 3s ease-out;
    }

    .animate-on-slidebottom-slow {
        animation: fromLeft 2s ease-out;
    }


}


@media screen and (max-width: 900px) {
    .fifth-component {
        flex-direction: column;
        align-items: center;

    }

}


@media screen and (max-width: 850px) {


    .transaction-discription p {
        display: none;
    }

    .regestration-component main {
        padding: 20px;
        padding-top: 100px;
        margin-top: 100px;
    }


    .third-component h1 {
        font-size: 78px;
    }

    .trending-stocks-display {
        justify-content: center;
    }

    .users-component {
        min-width: unset;
    }

    .real-estate-component,
    .first-project-component {
        margin-right: 0;
    }

    .first-project-component {
        padding: 20px;
    }

    .second-project-component-div {
        gap: 20px;
    }

    .calculated-section-investments,
    .my-investments-component,
    .real-estate-component,
    .users-component,
    .first-project-component,
    .invest-new-project,
    .my-statistic-account,
    .send-money-component,
    .my-account-component,
    .last-transaction-component {
        width: 100%;
    }

    .transaction-div,
    .investment-div {
        justify-content: space-between;
    }

    .lines-component {
        right: 50px;
    }

    .my-statistic-account-div-one section div,
    .my-statistic-account-div-two section div {
        width: 70px;
    }
}

@media screen and (max-width: 768px) {

    .third-component section {
        height: unset;
        width: 100%;
    }



    .fourth-component h1 {
        font-size: 78px;
        line-height: unset;
    }



    .second-component h1 {
        font-size: 78px;
        line-height: unset;
    }



    .back-footer-img {
        width: 100%;
    }


    .second-s-fc-button {
        display: none;
    }

    .second-s-fc-sbutton {
        width: 100%;
    }

    .partners-component h1 {
        font-size: 78px;
    }

    .project-div {
        width: 100%;
        height: unset;
    }

    .project-div img {
        width: 100%;
    }

    .third-component p {
        padding-right: unset;
    }

    .second-component p {
        margin-right: unset;
    }

    .fifth-component section:not(:last-child) {
        margin-right: unset;
    }

    .fifth-component section {
        margin-bottom: 40px;
    }

    .foutht-stat {
        right: 0;
        position: unset;
    }

    .fourth-component {
        margin: 0
    }

    .fourth-component h1 {
        padding: 20px;
    }

    .fourth-component p {
        padding: 20px;
    }

    .third-component section:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
    }


    .fourth-back {
        width: unset;
    }

    .animate-on-onesec {
        animation: fromLeft 2s ease-out;
    }

    .animate-on-dropdown {
        animation: fromLeft 3s ease-out;
    }

    .animate-on-godowntwosec {
        animation: fromLeft 4s ease-out;
    }

    .animate-on-godowntrisec {
        animation: fromLeft 5s ease-out;
    }

    .animate-on-godownfousec {
        animation: fromLeft 6s ease-out;
    }



    .circle-back img {
        top: 0;
    }


    .referal-program-section-two main {
        padding: 20px;
    }

    .h1-section-recent {
        gap: 20px;
    }

    .section-last-transactions {
        min-width: unset;
    }
}


@media screen and (max-width: 630px) {
    .news-list section {
        flex-wrap: wrap;
    }

    .news-section-one {
        justify-content: center;
        margin-bottom: 20px;
    }

    .second-f-fc {
        padding: 100px;
    }

    .social-links {
        flex-direction: column;
    }

    .social-links a:not(:last-child) {
        margin-right: unset;
    }

    .social-links a {
        justify-content: space-between;
    }

    .third-component p {
        padding-right: unset;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: unset;
    }

    .project-component {
        overflow: hidden;
        gap: 20px;
        padding-top: 85px;

    }



    .first-project-component h1 {
        font-size: 78px;
    }

    .first-project-component p {
        font-size: 16px;
    }

    .fourth-component p,
    .h1-statistic-account {
        font-size: 16px;
        line-height: unset;
    }

    .all-transactions-recent {
        padding: 20px;
    }




    .news-list section h2 {
        font-size: 18px;
    }

    .news-list p {
        font-size: 16px;
    }

    .news-list section span {
        font-size: 12px;
    }

    .news-list section p {
        font-size: 12px;
    }

    .arrow-section {
        display: flex;
        margin-top: 40px;
        justify-content: center;
        align-items: center;

    }

    .partners-component {
        padding: 0 20px;
        padding-top: 85px;
    }

    .foter-component {
        padding: 0 20px;
        padding-top: 60px;
        padding-bottom: 40px;
        justify-content: center;
    }

    .footer-links {
        align-items: center;
        flex-direction: row-reverse;
        margin-top: 20px;
    }

    .fifth-component {
        padding: 0 20px;
        padding-top: 60px;
    }

    .third-component {
        padding: 20px 20px;
        padding-top: 60px;
    }

    .second-component {
        padding: 0 20px;
    }

    .first-component-div {
        padding: 0 20px;
        padding-top: 85px;
    }

    .foter-component h1 {
        font-size: 32px;
    }

    .copyrating {
        margin-left: 20px;
    }

    .foter-component main {
        width: 100%;
    }

    .second-component p {
        padding-right: unset;
        font-size: 16px;
        line-height: unset;
    }

    .second-s-fc p {
        margin: 20px 0px 20px 0px;
    }

    .users-component h1 {
        font-size: 110px;
    }

    .news-component {
        padding-left: 20px;
    }

    .news-component h1 {
        font-size: 78px;
    }

    .third-component span {
        font-size: 16px;
    }

    .fifth-component section h1 {
        font-size: 32px;
    }

    .fifth-component section p {
        font-size: 16px;
    }

    .logo-header,
    .absolut-regestration-component {
        height: 50px;
    }

    .second-s-fc p {
        font-size: 16px;
    }

    .fourth-back {
        left: -100px;
    }

    .fifth-component section h1::after {
        height: 5px;
        bottom: -5px;
    }

    .first-project-component {
        height: 420px;
        width: 100%;
        margin: 0;
    }


    .broker-section {
        height: 420px;
    }

    .real-estate-component {
        height: 420px;
    }

    .users-component {
        height: 420px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .users-component div {
        align-items: flex-start;
    }

    .broker-section p {
        font-size: 16px;
    }

    .battery-broker {
        top: 125px;
        height: 130px;
        right: 10px;
    }

    .broker-section div {
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .button-news {
        height: 64px;
    }

    .broker-section span {
        font-size: 100px;
    }

    .first-project-component div {
        justify-content: center;
    }

    .news-list {
        padding: 20px;
    }

    .news-section-one img {
        width: 100%;
        padding: 0;
    }

    .investment-div section:nth-child(2) {
        display: none;
    }

    .expense-statistic-component img {
        width: 100%;
    }



    .my-statistic-account section {
        width: 170px;
        height: 85px;
    }


    .investment-div section {
        width: 130px;
    }

    .transaction-info {
        margin: 0 15px;
    }

    .header-section-div-two img:nth-child(3),
    .header-section-div-two img:nth-child(2),
    .balance-history-component,
    .expense-statistic-component {
        display: none;
    }

    .center-home-page,
    .top-three-lich-kabinet {
        justify-content: center;
    }

    .my-accounts-recent-transactions {
        display: none;
    }

    .top-up-fieldset:disabled {
        display: none;
    }

    .top-up-balance-inputs {
        min-width: unset;
    }

    .h2-top-up-balance-h2 {
        width: 100%;
    }

    .top-up-balance-inputs div,
    .top-up-balance-inputs button {
        max-width: unset;
    }

    .top-up-fieldset {
        width: 100%;
    }

    .my-statistic-account-div-two,
    .my-statistic-account-div-one {
        flex-wrap: wrap;
        justify-content: center;
    }

    .my-expense-component {
        display: none;
    }

    .info-transaction-div button {
        display: none;
    }

    .calendar-section-transaction {
        display: none;
    }

    .transactionid-transaction,
    .type-transaction,
    .card-transaction,
    .date-transaction,
    .h2-section-top-all-transaction {
        display: none;
    }

    .center-my-transactions,
    .main-transaction-head {
        justify-content: center;
    }

    .lich-kabinet-header {
        background-color: transparent;
        padding: 0;
    }





    .treding-stock-companys {
        width: 100%;
    }

    .settings-form-component {
        padding: 20px;
    }

    .edit-profile-settings {
        flex-direction: column;
        align-items: center;
    }

    .form-group input {
        width: unset;
    }

    .settings-header-h1 {
        justify-content: center;
    }

    .form-group,
    .button-save-settings {

        width: 100%;
    }

    .avatar-settings {
        height: 130px;
    }

    .edit-pen {
        top: 90px;
    }

    .my-profile-section-two a {
        padding: 10px;
        padding-left: 20px;
    }

    .my-profile-section-two img {
        height: 30px;
    }



    .my-statistic-account section p {
        font-size: 16px;
    }

    .my-statistic-account section h2 {
        font-size: 12px;
    }

    .my-statistic-account section img {
        height: 45px;
    }



    .lines-component {
        right: 30px;
    }

    .notifications-dropdown{
        right: 0;
        top: 60px;
    }






}

@media screen and (max-width: 565px) {
    .foutht-stat {
        width: 100%;
    }

    .second-f-fc h1 {
        font-size: 78px
    }



    .second-f-fc {
        padding: 40px;
    }

    .second-s-fc h2 {
        font-size: 42px;
    }

    .second-component {
        padding-top: 85px;
    }

    .third-component h1 {
        font-size: 60px;
    }

    .third-component section {
        padding: 30px 40px;
    }

    .news-component h1 {
        font-size: 78px;
    }

    .news-component {
        padding: 20px;
        padding-top: 85px;
    }

    .news-list p {
        height: unset;
    }

    .arrow-section img {
        display: none;
    }

    .arrow-section {
        padding: 0;
    }
}


@media screen and (max-width: 400px) {


    .second-component {
        padding: 20px;
    }

    .third-component {
        padding: 20px;
    }

    .fourth-component {
        padding: 20px;
    }

    .project-component {
        padding: 0 20px;
        padding-top: 150px;
    }

    .news-component {
        padding-left: 20px;
    }

    .foter-component {
        padding: 20px;
    }

    .copyrating {
        margin-left: 20px;
        font-size: 14px;
    }

}